import React, { useEffect, useState } from "react";
import "./CourseCard.css";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebaseconfig";
// import useRazorpay from "react-razorpay";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setCourse } from "../../features/course/courseSlice";
import SkeletonCourseCard from "../Loading/SkeletonCourseCard";

export default function CourseCard({ cat, filtering }) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  // const [Razorpay] = useRazorpay();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [darkMode, setDarkMode] = useState(null);
  const dark = useSelector((state) => state.darkmode.darkmode);

  useEffect(() => {
    setDarkMode(dark);
  }, [dark]);

  
  useEffect(() => {
    const fetchProduct = async (cat) => {
      try {
        setLoading(true);
        let productData = [];
        const snapshot = await getDocs(
          collection(db, `categories/${cat}/courses`)
        );
        productData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          timestamp: doc.data().timestamp.toDate(),
        }));

        if (filtering === "New") {
          const thirtyDaysAgo = new Date();
          thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
          productData = productData.filter((item) => {
            const itemDate = item.timestamp;
            return itemDate >= thirtyDaysAgo;
          });
        }

        if (filtering === "Trending") {
          productData = productData.filter((item) => {
            return item.isTrending === true;
          });
        }

        setData(productData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching products: ", error);
      }
    };

    fetchProduct(cat);
  }, [cat, filtering]);

  if (loading) {
    return <SkeletonCourseCard />;
  }

  return (
    <>
      {data.length <= 0 ? (
        <p style={{ color: "red" }}>No Courses to Show</p>
      ) : (
        <>
          {data.map((item) => (
            <div key={item.id} className="CourseCard">
              <Link
                to={"/course/" + item.category + "/" + item.id}
                onClick={() => dispatch(setCourse(item))}
              >
                <div className="CourseCard-Header">
                  <img
                    src={item.imageUrl}
                    height={250}
                    width={300}
                    style={{ objectFit: "cover", borderRadius: "4px" }}
                    alt="Course"
                  />
                  <span className="CourseCard-title">{item.title}</span>
                  <div className="price">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="none"
                        stroke={darkMode ? "white" : "black"}
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                        d="M6.5 3.5h11m-11 4.722h11M14.292 20.5L6.5 12.944h2.75c6.111 0 6.111-9.444 0-9.444"
                      />
                    </svg>
                    <span className="CourseCard-price-final">
                      {item.discountPrice}
                    </span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="15"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="none"
                        stroke={darkMode ? "white" : "black"}
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                        d="M6.5 3.5h11m-11 4.722h11M14.292 20.5L6.5 12.944h2.75c6.111 0 6.111-9.444 0-9.444"
                      />
                    </svg>
                    <span className="CourseCard-price-base">
                      {item.basePrice}
                    </span>
                  </div>
                </div>
                <p className="CourseCard-desc">{item.subtitle}</p>
                <ul className="CourseCard-lists">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <li className="CourseCard-list">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="150"
                        height="150"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill={darkMode ? "white" : "black"}
                          d="M19.5 4h-3V2.5a.5.5 0 0 0-1 0V4h-7V2.5a.5.5 0 0 0-1 0V4h-3A2.503 2.503 0 0 0 2 6.5v13A2.503 2.503 0 0 0 4.5 22h15a2.502 2.502 0 0 0 2.5-2.5v-13A2.502 2.502 0 0 0 19.5 4zM21 19.5a1.5 1.5 0 0 1-1.5 1.5h-15A1.5 1.5 0 0 1 3 19.5V11h18v8.5zm0-9.5H3V6.5C3 5.672 3.67 5 4.5 5h3v1.5a.5.5 0 0 0 1 0V5h7v1.5a.5.5 0 0 0 1 0V5h3A1.5 1.5 0 0 1 21 6.5V10z"
                        />
                      </svg>
                      <span>{item.startFrom}</span>
                    </li>
                    <li
                      className="CourseCard-list"
                      style={{ marginLeft: "0.5rem" }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="150"
                        height="150"
                        viewBox="0 0 16 16"
                        color=""
                      >
                        <path
                          fill={darkMode ? "white" : "black"}
                          fillRule="evenodd"
                          d="M7.5 13A5.506 5.506 0 0 1 2 7.5C2 4.467 4.467 2 7.5 2S13 4.467 13 7.5S10.533 13 7.5 13m0-12a6.5 6.5 0 1 0 0 13a6.5 6.5 0 0 0 0-13m3 6H8V3.5a.5.5 0 0 0-1 0v4a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 0-1"
                        />
                      </svg>
                      <span>
                        {item.duration} {item.durationCat}s
                      </span>
                    </li>
                    <li className="CourseCard-list">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="200"
                        height="200"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#FFE21F"
                          stroke="#FFE21F"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                          d="m12.495 18.587l4.092 2.15a1.044 1.044 0 0 0 1.514-1.106l-.783-4.552a1.045 1.045 0 0 1 .303-.929l3.31-3.226a1.043 1.043 0 0 0-.575-1.785l-4.572-.657A1.044 1.044 0 0 1 15 7.907l-2.088-4.175a1.044 1.044 0 0 0-1.88 0L8.947 7.907a1.044 1.044 0 0 1-.783.575l-4.51.657a1.044 1.044 0 0 0-.584 1.785l3.309 3.226a1.044 1.044 0 0 1 .303.93l-.783 4.55a1.044 1.044 0 0 0 1.513 1.107l4.093-2.15a1.043 1.043 0 0 1 .991 0"
                        />
                      </svg>
                      <span>{item.ratings}/5</span>
                    </li>
                  </div>
                  <div className="CourseCard-button-area">
                    <div
                      className="CourseCard-action action-enquary"
                    >
                      {/* <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 512 512"
                      >
                        <path
                          fill="currentColor"
                          d="m426.7 453.8l-38.1-79.1c-8.2-16.9-18.8-29.2-37.1-21.7l-36.1 13.4c-28.9 13.4-43.3 0-57.8-20.2l-65-147.9c-8.2-16.9-3.9-32.8 14.4-40.3l50.5-20.2c18.3-7.6 15.4-23.4 7.2-40.3l-43.3-80.6c-8.2-16.9-25-21-43.3-13.5c-36.6 15.1-66.9 38.8-86.6 73.9c-24 42.9-12 102.6-7.2 127.7c4.8 25.1 21.6 69.1 43.3 114.2c21.7 45.2 40.7 80.7 57.8 100.8c17 20.1 57.8 75.1 108.3 87.4c41.4 10 86.1 1.6 122.7-13.5c18.4-7.2 18.4-23.1 10.3-40.1z"
                        />
                      </svg> */}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 1024 1024"
                      >
                        <path
                          fill="currentColor"
                          d="M544 960q-124 0-233-60L0 1024l124-311Q64 604 64 480q0-98 38-186.5t102.5-153T357.5 38T544 0t186.5 38t153 102.5t102.5 153t38 186.5t-38 186.5t-102.5 153t-153 102.5T544 960zm160-384h-64l-36 32q-45-12-110.5-77.5T416 420l32-36v-64q0-17-12-34t-26.5-24.5T389 260l-47 47q-39 39-11.5 121.5t105 160t160 105T717 682l47-47q6-6-1.5-20.5T738 588t-34-12z"
                        />
                      </svg>
                      <span>Ask Expert</span>
                    </div>
                    <div className="CourseCard-action">
                      {/* <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        viewBox="0 0 32 32"
                      >
                        <path
                          fill="currentColor"
                          d="M4 7a1 1 0 0 0 0 2h2.22l2.624 10.5c.223.89 1.02 1.5 1.937 1.5h12.47c.903 0 1.67-.6 1.907-1.47L27.75 10h-2.094l-2.406 9H10.78L8.157 8.5A1.984 1.984 0 0 0 6.22 7H4zm18 14c-1.645 0-3 1.355-3 3s1.355 3 3 3s3-1.355 3-3s-1.355-3-3-3zm-9 0c-1.645 0-3 1.355-3 3s1.355 3 3 3s3-1.355 3-3s-1.355-3-3-3zm3-14v5h-3l4 4l4-4h-3V7h-2zm-3 16c.564 0 1 .436 1 1c0 .564-.436 1-1 1c-.564 0-1-.436-1-1c0-.564.436-1 1-1zm9 0c.564 0 1 .436 1 1c0 .564-.436 1-1 1c-.564 0-1-.436-1-1c0-.564.436-1 1-1z"
                        />
                      </svg> */}
                      <span>View Program</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 642 1024"
                      >
                        <path
                          fill="currentColor"
                          d="M622 637H368l142 296q12 24 3 48.5t-33.5 36t-49.5 2.5t-37-32L257 703L65 895q-27 0-46-19T0 831V24Q0 9 17.5 3T58 3t39 21l516 516q13 13 21.5 36t6 42t-18.5 19z"
                        />
                      </svg>
                    </div>
                  </div>
                </ul>
              </Link>
            </div>
          ))}
        </>
      )}
    </>
  );
}
