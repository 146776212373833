import "./InternshipPage.css";
import { IoLocationOutline, IoPlayCircleOutline } from "react-icons/io5";
import { SlCalender } from "react-icons/sl";
import { PiMoneyThin } from "react-icons/pi";
import { RxLapTimer } from "react-icons/rx";
import { FaTools } from "react-icons/fa";
import { FaLocationArrow } from "react-icons/fa6";
import { IoMdBook } from "react-icons/io";
import { internship } from "./InternshipData";
import { useParams } from "react-router-dom";

const data = [
  {
    icon: <IoPlayCircleOutline />,
    title: "START DATE",
    value: "Immediately",
  },
  {
    icon: <SlCalender />,
    title: "DURATION",
    value: "6 Months",
  },
  {
    icon: <PiMoneyThin />,
    title: "STIPEND",
    value: "Unpaid",
  },
  {
    icon: <RxLapTimer />,
    title: "APPLY BY",
    value: "7 Nov' 2024",
  },
];

function InternshipPage() {
  const { internId } = useParams();
  const interships = internship.find(i => i.id === internId);

  return (
    <div className="intern">
      {interships &&
        <div >
          <div style={{ marginBottom: '1.5%',display:'flex',justifyContent:'center', alignItems:'center' }}>
            <h2>{interships.title}</h2>
          </div>
          <div className="intern-details-view">
            <div className="active-box">
              <FaLocationArrow />
              <span>Actively Hiring</span>
            </div>
            <div className="intern-role">
              <span>Full stack Devloper</span>
            </div>
            <div className="intern-location">
              <IoLocationOutline />
              <span>Pan India</span>
            </div>
            <div className="intern-info">
              {data.map((item, index) => (
                <div className="intern-card" key={index}>
                  <div className="intern-heading">
                    {item.icon}
                    <span>{item.title}</span>
                  </div>
                  <div className="intern-val">
                    <span>{item.value}</span>
                  </div>
                </div>
              ))}
            </div>
            <div className="intern-time">
              <div className="intern-date active-box">
                <RxLapTimer />
                <span>2 weeks ago</span>
              </div>
              <div className="intern-type active-box">
                <IoMdBook />
                <span>Internship</span>
              </div>
              <div className="intern-mode active-box">
                <FaTools />
                <span>Part time</span>
              </div>
            </div>

          </div>
          <div className="intern-about">
            <div className="intern-about-heading">
              <span>About the Internship</span>
              <p>Selected intern's day-to-day responsibilities include:</p>
            </div>
            <div className="inter-about-desc">
              {interships.about.map((abt, index) => (
                <p>
                  {index + 1}. {abt}
                </p>
              ))}
            </div>
            <span>Requirements:</span>
            <div className="inter-about-req">
              {interships.requirement.map((r) => (
                <p>
                  - {r}
                </p>
              ))}
            </div>
            <span className="Intern-who-can-apply">Benifits:</span>
            <div className="inter-about-apply">
              {interships.benfits.map((benifit) => (
                <p>- {benifit}</p>
              ))}
            </div>
          </div>
          <div className="intern-detail-sec">
            <span>Perks</span>
            <div className="intern-detail-sec-inner">
              <div className="active-box intern-lor">
                <span>Placements Assistant</span>
              </div>
              <div className="active-box intern-wfh">
                <span>Work from home</span>
              </div>
              <div className="active-box intern-days">
                <span>5 days a week</span>
              </div>
            </div>
          </div>
          <div className="intern-company">
            <span>About GDCS</span>
            <div className="intern-company-desc">
              <p>
                GDCS established in 2020, having its corporate office in India, has
                expertise in Web & Mobile Application Development using advanced
                research, strategic thinking and planning, and innovative
                procurements and implementations to maximize client business reach
                and enhancing efficiency. Being an end-to-end Web Designing and
                Development Company, we get things done by creating long-term and
                tactical partnerships with the clients, brainstorm preliminary
                ideas, devising project plans, building & implementing
                cost-effective solutions, guided by well-directed support and
                maintenance procedures. Our goal is to provide better and more
                convenient services, ensuring a seamless experience from start to
                finish. Whether you need a captivating website, engaging content, or
                effective marketing strategies, GDCS is dedicated to helping you
                achieve your digital objectives with excellence and efficiency.
              </p>
            </div>
          </div>
          <div className="intern-apply-sec">
            <button
              onClick={() =>
                (window.location.href = "https://gdcs.in/forms/?ff_landing=1")
              } // Replace with your Google Form URL
              className="intern-apply-now"
            >
              Apply Now
            </button>
          </div>
        </div>
      }
    </div>
  );
}

export default InternshipPage;