import React from "react";
import logo from "../assets/images/darkLogo.png";

const Footer = () => {
    const mail = "skill@gdcs.in";
    const phoneNumber = "080 6218 0291";

    return (
        <>
            <footer className="footer">
                <div className="footer-top">
                    <div className="container">
                        <div className="footer-brand">
                            <a href="https://gdcs.in/" className="logo" >
                                <img src={logo} alt="GDCS Logo" style={{ objectFit: 'cover' }} />
                            </a>
                            <p className="footer-text">
                                Think Innovative, Do Creative
                            </p>
                            <ul className="social-list">
                                <li>
                                    <a href="#" className="social-link">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 14 14"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" d="M11.36 5.83H7.87a.51.51 0 0 0-.51.52v1.41a.51.51 0 0 0 .51.51h2.29a2.75 2.75 0 0 1-3 2.79c-2.24 0-3.32-1.9-3.32-4.06c0-2.16 1.16-4.06 3.32-4.06a4.07 4.07 0 0 1 2.64.86a.49.49 0 0 0 .72-.22l.63-1.44a.51.51 0 0 0-.15-.63a7.07 7.07 0 0 0-3.8-1C3.56.5 1.08 3.33 1.08 7c0 3.67 2.49 6.5 6.08 6.5s5.76-2.56 5.76-6c0-1.1-.44-1.67-1.56-1.67" /></svg>
                                    </a>
                                </li>
                                <li>
                                    <a href="#" className="social-link">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="currentColor" d="M18.901 1.153h3.68l-8.04 9.19L24 22.846h-7.406l-5.8-7.584l-6.638 7.584H.474l8.6-9.83L0 1.154h7.594l5.243 6.932ZM17.61 20.644h2.039L6.486 3.24H4.298Z" /></svg>
                                    </a>
                                </li>
                                <li>
                                    <a href="#" className="social-link">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16.5 7.5v0M8 4h8a4 4 0 0 1 4 4v8a4 4 0 0 1-4 4H8a4 4 0 0 1-4-4V8a4 4 0 0 1 4-4Zm7.5 8a3.5 3.5 0 1 1-7 0a3.5 3.5 0 0 1 7 0Z" /></svg>                                    </a>
                                </li>
                                <li>
                                    <a href="#" className="social-link">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 56 56"><path fill="currentColor" fill-rule="evenodd" d="M45.608 45.607h-7.415V34.002c0-2.766-.048-6.326-3.853-6.326c-3.86 0-4.449 3.015-4.449 6.129v11.802h-7.407V21.745h7.108v3.263h.103c.99-1.877 3.409-3.855 7.016-3.855c7.51 0 8.897 4.94 8.897 11.367zM14.123 18.485a4.299 4.299 0 0 1-4.303-4.301a4.3 4.3 0 0 1 8.6 0a4.3 4.3 0 0 1-4.297 4.301m-3.714 27.122h7.423V21.745H10.41zM49.3 3H6.688C4.653 3 3 4.614 3 6.606v42.787c0 1.992 1.653 3.608 3.688 3.608H49.3c2.04 0 3.699-1.616 3.699-3.608V6.606C53 4.614 51.34 3 49.301 3" /></svg>                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="footer-link-box">
                            <ul className="footer-list">
                                <li>
                                    <p className="footer-link-title">Courses</p>
                                </li>
                                <li>
                                    <a href="#" className="footer-link">
                                        Design
                                    </a>
                                </li>
                                <li>
                                    <a href="#" className="footer-link">
                                        Development
                                    </a>
                                </li>
                                <li>
                                    <a href="#" className="footer-link">
                                        Marketing
                                    </a>
                                </li>
                                <li>
                                    <a href="#" className="footer-link">
                                        Content Writing
                                    </a>
                                </li>
                            </ul>
                            <ul className="footer-list">
                                <li>
                                    <p className="footer-link-title">Company</p>
                                </li>
                                <li>
                                    <a href="/about" className="footer-link">
                                        About
                                    </a>
                                </li>
                                <li>
                                    <a href="/terms&condition" className="footer-link">
                                        Terms
                                    </a>
                                </li>
                                <li>
                                    <a href="/privacy-policy" className="footer-link">
                                        Privacy Policy
                                    </a>
                                </li>
                                <li>
                                    <a href="/refund-policy" className="footer-link">
                                        Refund Policy
                                    </a>
                                </li>
                                <li>
                                    <a href="/#project" className="footer-link">
                                        Careers
                                    </a>
                                </li>
                            </ul>
                            <ul className="footer-list">
                                <li>
                                    <p className="footer-link-title">Services</p>
                                </li>
                                <li>
                                    <a href="https://gdcs.in/services/Desktop%20App%20Developing" className="footer-link">
                                        Web Design
                                    </a>
                                </li>
                                <li>
                                    <a href="https://gdcs.in/services/App%20Development" className="footer-link">
                                        App Development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://gdcs.in/services/Digital%20Marketing" className="footer-link">
                                        Marketing
                                    </a>
                                </li>
                                <li>
                                    <a href="https://gdcs.in/services/Content%20Writing" className="footer-link">
                                        Content Writing
                                    </a>
                                </li>
                            </ul>
                            <ul className="footer-list">
                                <li>
                                    <p className="footer-link-title">Contact</p>
                                </li>
                                <li className="contact-item">
                                    <span>Call : </span>
                                    <a href={`tel:${phoneNumber}`} className="contact-link">
                                        {phoneNumber}
                                    </a>
                                </li>
                                <li className="contact-item">
                                    <span>Email : </span>
                                    <a href={`mailto:${mail}`} className="contact-link">
                                        {mail}
                                    </a>
                                </li>
                                <li className="contact-item">
                                    <span>Address : </span>
                                    <a href="https://www.google.com/maps/place/Bhubaneswar,+Odisha/@20.300807,85.6556413,11z/data=!3m1!4b1!4m6!3m5!1s0x3a1909d2d5170aa5:0xfc580e2b68b33fa8!8m2!3d20.2960587!4d85.8245398!16zL20vMDNjenFz?entry=ttu" className="contact-link">
                                        <address>Plot No- 502/1237, Ogalapada, Industrial Estate, Janla, Bhubaneswar, Odisha 752054</address>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <div className="container">
                        <p className="copyright">
                            © 2024 <a href="https://gdcs.in/">GDCS</a>. All right reserved
                        </p>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default Footer;
