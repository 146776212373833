import React from "react";
import "./ProjectCategory.css";
import { internship } from "../../Pages/Internship/InternshipData";

const ProjectCategory = ({ id }) => {
  return (
    <section id={id} class="section_Pcat category_Pcat" aria-label="category">
      <div class="container_Pcat">
        <p class="section-subtitle_Pcat">Project Cources</p>

        <h2 class="h2_Pcat section-title_Pcat">
          Online Classes For Remote Learning
        </h2>

        <ul class="grid-list_Pcat">
          {internship.map((i) => (
            <li>
              <a href={`/intern/${i.id}`}>
                <div
                  class="category-card_Pcat"
                  style={{
                    backgroundColor: "hsla(42, 94%, 55%,0.1)",
                    borderRadius: "10px",
                  }}
                >

                  <h3 class="h3_Pcat">
                    <p class="card-title_Pcat">
                      {i.title}
                    </p>
                  </h3>

                  <p class="card-text_Pcat" style={{ padding: '5px', backgroundColor: '#FFE21F', borderRadius: '10px', color: 'black', fontSize: '1.2em' }}>
                    Apply
                  </p>
                </div>
              </a>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default ProjectCategory;
