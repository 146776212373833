import React, { useState } from "react";
import "./Home.css";
import Category from "../../components/MainCategory/mainCategory";
import ProjectCategory from "../../components/ProjectCategory/ProjectCategory";
import About from "../../components/About/About";
import Popular from "../../components/PopularCourse/PopularCourse";
import StudentEnroll from "../../components/StudentEnrolled/StudentEnrolled";
import Email from "../../components/Email/Email";
import PopularCourseList from "../../components/PoppularCourseList/PopularCourseList";

const Home = ({ darkMode }) => {

  return (
    <>
      <body className={darkMode ? "" : "dark"}>
        <main>
          <article>
            {/* - #HERO\*/}
            <section className="hero">
              <div className="container">
                <figure className="hero-banner">
                  <img
                    src={require("../../assets/images/hero-banner.png")}
                    alt="A young lady sits, holding a pen and a notebook."
                  />
                </figure>
                <div className="hero-content">
                  <h1 className="h1 hero-title">Start Your Future With Us</h1>
                  <p className="section-text">
                    Welcome to GDCS Skill Academy where we bridge the gap between
                    education and industry through our exceptional internship
                    and real-world project opportunities. Discover our
                    comprehensive range of internship and project courses,
                    meticulously designed to shape your future. Our exclusive
                    programs offer hands-on experience and practical knowledge,
                    ensuring you develop the skills needed to excel in your
                    chosen field.
                  </p>
                  <p className="section-text">
                    With guidance from experienced mentors and a supportive
                    learning environment, you will work on real-world projects
                    that significantly enhance your professional
                    capabilities.Whether you're a student eager to gain industry
                    exposure or a professional looking to enhance your
                    capabilities, we provide the perfect platform to elevate
                    your career.
                  </p>
                  <p className="section-text">
                    GDCS Skill Academy is your gateway to real-world experience and career
                    advancement. Start your journey with us and shape your
                    future today..
                  </p>

                  <a href={"/#project"}>
                    <button
                      href={"/#project"} 
                      className="btnf1 btn-primaryf1">
                      Discover More
                    </button>
                  </a>
                </div>
              </div>
            </section>

            {/* Internship Categories Section */}
            <Category id={"internship"} />

            {/* - #ABOUT */}
            <section className="about has-bg-image_Pop">
              <div className="container">
                <figure className="about-banner">
                  <img
                    src={require("../../assets/images/about-banner.png")}
                    alt="Eduland students"
                    className="about-img"
                  />
                </figure>
                <div className="about-content">
                  <h2 className="h2 about-title">
                    We Help to Create Possibility & Success in Your Career!
                  </h2>
                  <p className="section-text">
                    Continually administrate process-centric human capital
                    rather than bleeding-edge methodologies. Distinctively
                    supply accurate methods of empowerment before.
                  </p>
                  <p className="section-text">
                    Continually administrate process-centric human capital
                    rather than bleeding-edge methodologies. Distinctively
                    supply accurate methods of empowerment before.
                  </p>
                  <p className="section-text">
                    Continually administrate process-centric human capital
                    rather than bleeding-edge methodologies. Distinctively
                    supply accurate methods of empowerment before.
                  </p>
                </div>
              </div>
            </section>

            <ProjectCategory id="project" />

            <About id={"about"} />

            <PopularCourseList id={"blog"} />

            <StudentEnroll />
            <Email />
          </article>
        </main>
      </body>
    </>
  );
};

export default Home;
