export const internship = [
    {
        id: "internship1",
        title: "Fullstack Web Dev Internship",
        about: [
            "Collaborate with the development team to create, maintain, and optimize web applications.",
            "Write clean, well-structured code in HTML, CSS, and JavaScript.",
            "Assist in developing front-end and back-end components using modern frameworks and libraries.",
            "Participate in brainstorming sessions and contribute creative ideas for web design and features.",
            "Perform debugging and testing to ensure functionality and usability across multiple browsers and devices.",
            "Keep up-to-date with the latest industry trends and technologies."
        ],
        requirement: [
            "Strong knowledge of HTML, CSS, and JavaScript. - Excellent problem-solving skills and attention to detail.",
            "Familiarity with front-end frameworks such as React, Angular, or Vue.js is a plus.",
            "Basic understanding of back-end technologies like Node.js, JAVA, or Python.",
            "Ability to work in a collaborative environment and handle multiple tasks.",
            "Prior experience with web development projects or relevant coursework is preferred."
        ],

        benfits: [
            "Hands-on experience working on real-world projects.",
            "Mentorship and guidance from experienced professionals.",
            "Opportunity to enhance your coding and problem-solving skills.",
            "Networking opportunities and a chance to build connections with in the industry.",
            "A potential full-time offer based on performance and business needs."
        ]

    },

    {
        id: "internship2",
    title: "Fullstack App Dev Internship",
        about: [
            "Assist with coding and development by collaborating with senior developers.",
            "Participate in design meetings to contribute ideas on UI and UX principles.",
            "Conduct testing and quality assurance for functionality and usability.",
            "Document development processes to maintain clear project communication.",
            "Research new technologies and present findings to the team.",
            "Collaborate with cross-functional teams to integrate various project aspects."
        ],
        requirement: [
            "Basic knowledge of programming languages: Familiarity with languages such as Java, Swift, JavaScript, or Python.  ",
            "Understanding of mobile or web development frameworks: Experience with frameworks like React Native, Flutter, or Angular is a plus.",
            "Strong problem-solving skills: Ability to analyze issues and develop effective solutions.  ",
            "Familiarity with version control systems: Basic knowledge of Git and version control practices.  ",
            "Good communication skills: Ability to collaborate effectively with team members and present ideas clearly.  "
        ],

        benfits: [
            "Gain hands-on experience with real-world projects and technologies.  ",
            "Develop a strong understanding of industry best practices and methodologies.  ",
            "Enhance your technical skills and expand your programming knowledge.  ",
            "Build a professional network by collaborating with experienced developers and teams.  ",
            "Improve your resume with practical experience, making you more competitive in the job market."
        ]

    },

    {
        id: "internship3",
        title: "UI / UX -- App / Web Dev",
        about: [
            "Conduct user research and gather feedback to understand user needs and behaviors.  ",
            "Design wireframes, prototypes, and high-fidelity mockups for both web and mobile applications.  ",
            "Collaborate with developers and product managers to ensure design feasibility and alignment with project goals.",
            "Create and maintain a design system to ensure consistency across platforms and products.  ",
            "Perform usability testing to identify areas for improvement and iterate on designs based on user feedback.  ",
            "Stay updated on design trends and emerging technologies to continuously enhance the user experience.  "
        ],
        requirement: [
            "Proficiency in design tools such as Adobe XD, Sketch, Figma, or InVision.  ",
            "Strong understanding of user-centered design principles and best practices.  ",
            "Experience with wireframing and prototyping for both web and mobile applications.  ",
            "Familiarity with front-end development technologies like HTML, CSS, and JavaScript is a plus.  ",
            "Excellent communication and collaboration skills to work effectively with cross-functional teams."
        ],

        benfits: [
            "Opportunity to shape user experiences and impact product success directly.  ",
            "Continuous learning through exposure to the latest design trends and technologies.  ",
            "Collaborative work environment that fosters creativity and teamwork.  ",
            "Development of a diverse skill set that includes design, research, and problem-solving.  ",
            "High demand for UI/UX skills in the job market, leading to strong career prospects and opportunities."
        ]

    },

    {
        id: "internship4",
        title: "JAVA Bcack-end Dev",
        about: [
            "Develop and maintain robust, scalable server-side applications using Java frameworks such as Spring or Hibernate.  ",
            "Design and implement APIs to facilitate communication between front-end and back-end systems.  ",
            "Collaborate with front-end developers to integrate user-facing elements with server-side logic.  ",
            "Optimize application performance and ensure high availability through effective coding practices and troubleshooting.",
            "Conduct unit and integration testing to ensure software quality and reliability.  ",
            "Participate in code reviews and contribute to team knowledge sharing to improve overall development processes.  "
        ],
        requirement: [
            "Proficiency in Java programming and experience with frameworks like Spring or Hibernate.  ",
            "Strong understanding of RESTful API design and implementation.  ",
            "Familiarity with database management systems such as MySQL, PostgreSQL, or MongoDB.  ",
            "Experience with version control systems, particularly Git.  ",
            "Knowledge of software development best practices, including testing, debugging, and code review processes."
        ],

        benfits: [
            "High demand for Java skills, leading to strong job security and career opportunities.  ",
            "Competitive salary potential, as back-end developers are crucial to application development.  ",
            "Opportunity to work on complex and challenging projects that enhance problem-solving skills.  ",
            "Exposure to various technologies and tools, promoting continuous learning and professional growth.  ",
            "Ability to contribute to the architecture and scalability of applications, making a significant impact on user experience."
        ]

    },

]