
import { db } from ".././firebaseconfig";
import { addDoc, collection } from "firebase/firestore";

export const sentEmail = async (data) => {

  try {
    await addDoc(collection(db, "mail"), {
      from: "info@gdcs.in",
      to: ["mybusoah@gmail.com"],
      message: {
        subject: '𝑪𝒐𝒏𝒈𝒓𝒂𝒕𝒖𝒍𝒂𝒕𝒊𝒐𝒏𝒔! 𝐀𝐩𝐩𝐥𝐢𝐜𝐚𝐭𝐢𝐨𝐧 𝐑𝐞𝐜𝐞𝐢𝐯𝐞𝐝 𝐟𝐨𝐫 𝐕𝐢𝐫𝐭𝐮𝐚𝐥 𝐈𝐧𝐭𝐞𝐫𝐧𝐬𝐡𝐢𝐩 𝐰𝐢𝐭𝐡 𝐈𝐧𝐝𝐮𝐬𝐭𝐫𝐲 𝐄𝐱𝐩𝐞𝐫𝐭𝐬; 𝐆𝐞𝐭 𝐆𝐥𝐨𝐛𝐚𝐥𝐥𝐲 𝐀𝐜𝐜𝐞𝐩𝐭𝐞𝐝 𝐂𝐞𝐫𝐭𝐢𝐟𝐢𝐜𝐚𝐭𝐢𝐨𝐧',
        text: `Warm greetings from Gdsc Skill Academy! 

      Dear ${"Developer Team"},

      Congratulations! Team GDCS Skill Academy has successfully received your application for ""${"Java"}"". We are glad to say that all the required processes have been successfully completed. Batch starting from 23rd Aug 2022.

      Regarding your class timing and session joining link, the training team will share another email with you by 23rd Aug 2022. For any further queries, feel free to connect with us; we are always available for your concerns.

      Registered Program: "${data.course}"
      Course Fees Paid to ESP: "${data.price}"
      Date of Payment: "${data.date}"

      If you are comfortable, please help us share this information with your friends & juniors, so they can also reach this platform to polish their technical skills with us.

      For any kind of queries/doubts, feel free to connect with us over call or WhatsApp. We will be happy to help.

      _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ 
      Wishing you good luck & Welcome to the Gdsc Skill Academy...

      --

      Regards,
      Training Department
      GDCS Skill Academy`,
      }
    });

    // Define email content for the developer
    await addDoc(collection(db, "mail"), {
      to: `${data.email}`,
      message: {
        subject: 'New Form Submission Received',
        text: `Dear "${data.firstName} ${data.lastName}"

      I wanted to take a moment to express my sincere gratitude for enrolling in ${data.course} Thank you for choosing to invest your time and energy into this learning experience.

      It was an honor to have you as a student in our course. Your active participation, dedication, and enthusiasm made a significant contribution to the success of the program. I hope that you found the course to be informative, engaging, and valuable.

      Please know that our team is always here to support you in your educational journey. If you have any questions or need any assistance, please do not hesitate to reach out to us.

      Once again, thank you for your commitment and dedication to this course. We wish you all the best in your future endeavors.

      Best regards,
      Training Team
      Gdcs Skill Academy`
      }
    });


    // firestore.collection('Email').add(userEmailContent);
    // firestore.collection('Email').add(developerEmailContent);

    alert('Emails sent successfully!');
  } catch (error) {
    console.error('Error sending emails:', error);
    alert('Error sending emails. Please try again.');
  }
};