import firebase from 'firebase/compat/app';
import { getFirestore } from 'firebase/firestore';
// import { getAnalytics } from 'firebase/analytics';
import 'firebase/compat/firestore';


const firebaseConfig = {
  apiKey: "AIzaSyAKnFxlpr3MH9Fpe9bvG4OJsKfwDLf5Xb4",
  authDomain: "college-planet-1fb2c.firebaseapp.com",
  projectId: "college-planet-1fb2c",
  storageBucket: "college-planet-1fb2c.appspot.com",
  messagingSenderId: "1020366335345",
  appId: "1:1020366335345:web:f19b3d99961c10915c5ad0",
  measurementId: "G-ZYP5E33YXB"
};

const app = firebase.initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const firestore = firebase.firestore(app);
export default firebase;