export const handlePayment = async (Razorpay, data, onFailure, paymentStatus) => {
  const options = {
    key: "rzp_live_kWpqyMRivGET0R", // Add your Razorpay API key here
    amount: data.price * 100, // Amount is in paise, so multiply by 100
    currency: "INR",
    name: "GDCS",
    description: "Test Transaction",
    image: "https://example.com/your_logo", // Add your logo URL here
    handler: function (response) {
      // Payment successful, call the success callback
      // alert("Payment Sucess");
      paymentStatus("Success");
    },
    prefill: {
      name: data.firstName,
      email: data.email,
      contact: data.contactNumber,
    },
    notes: {
      address: "Razorpay Corporate Office",
    },
    theme: {
      color: "#3399cc",
    },
  };

  const rzp1 = new window.Razorpay(options);

  // Handle payment failures
  rzp1.on("payment.failed", function (response) {
    // alert("Payment Failed 1");
    paymentStatus("Failed");
    
    // Check if the failure was due to user cancellation
  });

  // Handle payment open errors
  try {
    rzp1.open(); // Open the Razorpay checkout UI
  } catch (error) {
    console.error("Error opening Razorpay checkout:", error);
    if (onFailure) {
      onFailure({ error: { code: "checkout_error", description: "Failed to open Razorpay checkout." } });
    }
  }
};



// export const handlePayment = async (Razorpay, data, onSuccess, onFailure, onCancel) => {
//   const options = {
//     key: "rzp_live_kWpqyMRivGET0R", // Add your Razorpay API key here
//     amount: data.price * 100, // Amount is in paise, so multiply by 100
//     currency: "INR",
//     name: "GDCS",
//     description: "Test Transaction",
//     image: "https://example.com/your_logo", // Add your logo URL here
//     handler: function (response) {
//       // Payment successful, call the success callback
//       // if (onSuccess) {
//       //   onSuccess(response);
//       // }
//     },
//     prefill: {
//       name: data.firstName,
//       email: data.email,
//       contact: data.contactNumber,
//     },
//     notes: {
//       address: "Razorpay Corporate Office",
//     },
//     theme: {
//       color: "#3399cc",
//     },
//   };

//   const rzp1 = new Razorpay(options);

//   // Handle payment failures
//   rzp1.on("payment.failed", function (response) {
//     console.error("Payment Failed", response);
//     alert("failed");
    
//     // Check if the failure was due to user cancellation
//     const userCancelled = response.error.reason === "user_cancelled" ||
//       response.error.code === "payment_cancelled" ||
//       response.error.description.includes("cancelled") ||
//       response.error.reason === "user_declined";

//     if (userCancelled) {
//       if (onCancel) {
//         onCancel(response);
//         alert("Cancel");
//       }
//     } else {
//       if (onFailure) {
//         onFailure(response);
//       }
//     }
//   });

//   // Handle payment open errors
//   try {
//     rzp1.open(); // Open the Razorpay checkout UI
//   } catch (error) {
//     console.error("Error opening Razorpay checkout:", error);
//     if (onFailure) {
//       onFailure({ error: { code: "checkout_error", description: "Failed to open Razorpay checkout." } });
//     }
//   }
// };

// const onCancel = (response) => {
//   console.log("Payment was cancelled by the user.", response);
//   alert("Payment was cancelled by the user.");
// };
